<template>
	<div class="">
		<CommomListing
			:tbody="tbody"
			:allowedSortedThead="allowedSortedThead"
			:sourceList="sourceList"
			@openReminder="open_reminder_drawer($event)"
			@openReminderlist="open_reminder_list($event)"
			v-on:reload:content="getdata()"
			:currentPage="currentPage"
			:totalPage="totalPage"
			:showingString="showingString"
			v-on:createSuccess="getdata()"
		

		></CommomListing>
		<FullDialog :dialog="remarkDialog" :dialog-width="1200">
			<template #title>
				<div class="text_climpNotes">{{ name }} ({{ leadBarcode }})</div>
				<v-spacer></v-spacer>
				<v-btn
					depressed
					tile
					class="text-white"
					color="red darken-4"
					:disabled="pageLoading"
					icon
					@click="closeDialog('remark', 'lead')"
				>
					<v-icon>mdi-close-circle</v-icon>
				</v-btn>
			</template>
			<template #subtitle>subtitle</template>
			<template #body>
				<AllNotesTab
					:relatedId="lead_id"
					:relatedType="'lead-notes'"
					:siggest-list="notesUserArr"
					create-url="lead-note"
					get-url="lead-note"
				>
				</AllNotesTab>
			</template>
		</FullDialog>
		<CreateOpportunityDrawer
			v-if="update_opportunity"
			:id="id"
			:drawer="update_opportunity"
			title-status="Update"
			v-on:close="update_opportunity = false"
			v-on:createSuccess="getdata()"
		></CreateOpportunityDrawer>
		<Dialog
			v-if="reminderListDialog"
			:dialog="reminderListDialog"
			:dialog-width="1200"
			:content-class="`testdata ${open_reminder_dialog ? 'd-none' : ''}`"
			@close="reminderListDialog = false"
		>
			<template v-slot:title>
				Leads Reminder<template v-if="lead_company"> :- {{ lead_company }}</template>
				<v-spacer></v-spacer>
			</template>
			<template v-slot:body>
				<v-layout class="px-4 py-2">
					<v-tabs
						background-color="transparent"
						color="blue"
						class="custom-tab-transparent w-100"
						active-class="blue darken-4 text-white"
						hide-slider
						v-model="reminderTabs"
					>
						<v-tab href="#overdue" v-on:click="getReminder(reminderId, 1)">
							<v-icon small left>mdi-alarm-check</v-icon>Overdue ({{
								reminderCounting && reminderCounting.overdue_reminders_count
									? reminderCounting.overdue_reminders_count
									: 0
							}})</v-tab
						>
						<v-tab href="#upcoming" v-on:click="getReminder(reminderId, 2)">
							<v-icon small left>mdi-alarm-check</v-icon>upcoming ({{
								reminderCounting && reminderCounting.upcoming_reminders_count
									? reminderCounting.upcoming_reminders_count
									: 0
							}})</v-tab
						>
						<v-tab href="#complete" v-on:click="getReminder(reminderId, 3)">
							<v-icon small left>mdi-alarm-check</v-icon>Completed ({{
								reminderCounting && reminderCounting.completed_reminders_count
									? reminderCounting.completed_reminders_count
									: 0
							}})</v-tab
						>
					</v-tabs>
				</v-layout>
				<div class="tab-layout-content px-4">
					<v-tabs-items v-model="reminderTabs">
						<v-tab-item value="overdue">
							<template v-if="reminderData && reminderData.length > 0">
								<div v-for="(reminder, index) in reminderData" :key="index">
									<ReminderTab
										:open_details="!index ? true : false"
										:remind="reminder"
										v-on:editReminder="editReminderForm($event, reminder)"
										:index-reminder="index"
										v-on:reminderSuccess="refreshReminder"
										v-on:reminderSuccessNew="deleteRefreshReminder($event)"
										v-on:deleteSuccess="deleteRefreshReminder($event)"
									></ReminderTab>
								</div>
							</template>
							<template v-else>
								<p class="m-0 row-not-found text-center">
									<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
									Uhh... There are no reminder at the moment.
								</p>
							</template>
						</v-tab-item>
						<v-tab-item value="upcoming">
							<template v-if="reminderData && reminderData.length > 0">
								<div v-for="(reminder, index) in reminderData" :key="index">
									<ReminderTab
										:open_details="!index ? true : false"
										:remind="reminder"
										v-on:editReminder="editReminderForm($event, reminder)"
										:index-reminder="index"
										v-on:reminderSuccessNew="deleteRefreshReminder($event)"
										v-on:deleteSuccess="deleteRefreshReminder($event)"
									></ReminderTab>
								</div>
							</template>
							<template v-else>
								<p class="m-0 row-not-found text-center">
									<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
									Uhh... There are no reminder at the moment.
								</p>
							</template>
						</v-tab-item>
						<v-tab-item value="complete">
							<template v-if="reminderData && reminderData.length > 0">
								<div v-for="(reminder, index) in reminderData" :key="index">
									<ReminderTab
										:open_details="!index ? true : false"
										:remind="reminder"
										v-on:editReminder="editReminderForm($event, reminder)"
										:index-reminder="index"
										v-on:reminderSuccessNew="deleteRefreshReminder($event)"
										v-on:deleteSuccess="deleteRefreshReminder($event)"
									></ReminderTab>
								</div>
							</template>
							<template v-else>
								<p class="m-0 row-not-found text-center">
									<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
									Uhh... There are no reminder at the moment.
								</p>
							</template>
						</v-tab-item>
					</v-tabs-items>
				</div>
			</template>
			<template v-slot:action>
				<v-btn class="close-btn" depressed tile v-on:click="closeDialog"> Close </v-btn>
			</template>
		</Dialog>
	</div>
</template>
<script>
import FullDialog from "@/view/components/FullDialog";
import CommomListing from "@/view/module/opportunity/CommomListing";

import AllNotesTab from "@/view/pages/leads/components/NotesTab3";
import MomentJS from "moment-timezone";
import objectPath from "object-path";
import { mapGetters } from "vuex";
import ListingMixin from "@/core/mixins/listing.mixin";
import CreateOpportunityDrawer from "@/view/pages/leads/create/CreateOpportunityDrawer";
import { GET, QUERY } from "@/core/services/store/request.module";

import { SET_MESSAGE } from "@/core/services/store/common.module";
export default {
	name: "OpportunityListing",
	mixins: [ListingMixin],
	components: {
		FullDialog,
		AllNotesTab,
		CreateOpportunityDrawer,
		CommomListing,
		ReminderTab: () => import("@/view/pages/leads/components/ReminderTab"),
	},

	props: {
		statusFilterData: {
			type: Array,
		},
	},
	/* watch:{
		tbodys : {
			deep : true,
			indeterminate : true,
			handle(params){
                 this.tbody = params;
 			}
		}
	}, */
	data() {
		return {
			remarkDialog: false,
			pageLoading: null,
			update_opportunity: false,
			leadBarcode: null,
			notesUserArr: [],
			name: null,
			lead_id: null,
			open_reminder_dialog: false,
			reminderListDialog: false,
			sourceList: [
				// { text: "All", type: 2, value: "" },
				// { text: "Web Enquiry", value: "web-enquiry", type: 2 },
				// { text: "Web Chat", value: "web-chat", type: 2 },
				// { text: "Email Marketer", value: "email-marketer", type: 2 },
				// { text: "Tele Call", value: "tele-call", type: 2 },
				// { text: "Google Adwords", value: "google-adwords", type: 2 },
				// { text: "FSM/AMS(Brochure Lead Form)", value: "fsm-ams", type: 2 },
				// { text: "Facebook", value: "facebook", type: 2 },
				// { text: "Referral", value: "referral", type: 2 },
				// { text: "Intern", value: "intern", type: 2 },
				// { text: "None", value: "none", type: 2 },
			],
			opportunityList: [
				{ text: "No Opportunity", value: null },
				{ text: "All", type: 3, value: "" },
				{ text: "50 50", value: "50-50", type: 3 },
				{ text: "High Chance", value: "high-chance", type: 3 },
				{ text: "None", value: "none", type: 3 },
				{ text: "Low Chance", value: "low-chance", type: 3 },
			],
			productList: [
				// { text: "All", type: 4, value: "" },
				// { text: "SEO", value: "seo", type: 4 },
				// { text: "FSM", value: "fsm", type: 4 },
				// { text: "Asset Management", value: "asset-management", type: 4 },
				// { text: "Custom Soft", value: "custom-soft", type: 4 },
				// { text: "CRM", value: "crm", type: 4 },
				// { text: "Inventory", value: "inventory", type: 4 },
				// { text: "OMS", value: "oms", type: 4 },
				// { text: "DMS", value: "dms", type: 4 },
				// { text: "IT Services", value: "it-services", type: 4 },
				// { text: "VMS", value: "vms", type: 4 },
				// { text: "ID Software", value: "id-software", type: 4 },
				// { text: "Genic Cards", value: "genic-cards", type: 4 },
				// { text: "Software", value: "software", type: 4 },
			],
			statusList: [
				{
					id: 2,
					text: "Not Contacted",
					value: "not-contacted",
					type: 1,
					provider_type: null,
					seo: 0,
					hosting: 0,
					domain: 0,
					status_color: "purple",
					count: 0,
					child_name: null,
					cost: null,
					activation_date: null,
					renewal_basis: null,
					renewal_date: null,
					status_count: 165,
				},
				{
					id: 3,
					text: "Follow UP",
					value: "follow-up",
					type: 1,
					provider_type: null,
					seo: 0,
					hosting: 0,
					domain: 0,
					status_color: "orange",
					count: 0,
					child_name: null,
					cost: null,
					activation_date: null,
					renewal_basis: null,
					renewal_date: null,
					status_count: 118,
				},
				{
					id: 4,
					text: "Meeting",
					value: "meeting",
					type: 1,
					provider_type: null,
					seo: 0,
					hosting: 0,
					domain: 0,
					status_color: "pink",
					count: 0,
					child_name: null,
					cost: null,
					activation_date: null,
					renewal_basis: null,
					renewal_date: null,
					status_count: 49,
				},
				{
					id: 5,
					text: "Quotation",
					value: "quotation",
					type: 1,
					provider_type: null,
					seo: 0,
					hosting: 0,
					domain: 0,
					status_color: "cyan",
					count: 0,
					child_name: null,
					cost: null,
					activation_date: null,
					renewal_basis: null,
					renewal_date: null,
					status_count: 11,
				},
				{
					id: 6,
					text: "Rejected",
					value: "reject",
					type: 1,
					provider_type: null,
					seo: 0,
					hosting: 0,
					domain: 0,
					status_color: "red",
					count: 0,
					child_name: null,
					cost: null,
					activation_date: null,
					renewal_basis: null,
					renewal_date: null,
					status_count: 148,
				},
				{
					id: 7,
					text: "Accepted",
					value: "accept",
					type: 1,
					provider_type: null,
					seo: 0,
					hosting: 0,
					domain: 0,
					status_color: "green",
					count: 0,
					child_name: null,
					cost: null,
					activation_date: null,
					renewal_basis: null,
					renewal_date: null,
					status_count: 22,
				},
			],
			// tbody: [

			// 	{
			// 		id: 2,
			// 		barcode: "BT-0000346",
			// 		pin: 1,
			// 		pinned: 0,
			// 		status: "Not Contacted",
			// 		opportunity: "High Chance",
			// 		source: "Google Adwords",
			// 		product_type: "SEO",
			// 		company_name: "abc Corp",
			// 		company_email: "abccorp@gmail.com",
			// 		company_website: "abcbthrust.com",
			// 		contact_name: "John doe",
			// 		contact_email: "info@gmail.com",
			// 		comtact_number: "+65-12345678",
			// 		notes: "",
			// 		reminder: [
			// 			{
			// 				start_date: "02/06/2024",
			// 				end_date: "02/06/2024",
			// 			},
			// 		],
			// 		unit_number: "72 Bendemeer Rd",
			// 		address_line_1: "#07-03 Luzerne S()",
			// 		address_line_2: "28 Lor 23 Geylang, 00074",
			// 		country: "Singapore",
			// 		postal_code: "11002",
			// 		amount: "123",
			// 		added_at: "1 Months Ago",
			// 	},
			// ],
			// tbodyNew : [],
			typeId: 2,
			actionList: [
				{ title: "View ", value: "view_opportunity", icon: "mdi-eye" },
				{ title: "Edit ", value: "edit_opportunity", icon: "mdi-pencil" },
				{ title: "Delete", value: "delete_opportunity", icon: "mdi-delete" },
			],
			confirm_dialog: false,
			create_contact: false,
			type: null,
			checkbox: false,
			/* 	currentPage: 1, */
			totalRows: 0,
			rowsOffset: 0,
			totalPages: 0,
			showingFrom: 0,
			showingTo: 0,
			editTask: {},
			attendieList: {},
		};
	},

	methods: {
		// getSetting() {
		// 	const _this = this;
		// 	_this.$store
		// 		.dispatch(GET, {
		// 			url: `single-setting/${this.typeId}`,
		// 		})
		// 		.then((data) => {
		// 			if (data && data.length) {
		// 				this.sourceList = data;
		// 			}
		// 			// this.$emit("success", true);
		// 		})
		// 		.catch((error) => {
		// 			console.log({
		// 				error,
		// 			});
		// 		});
		// },
		getSetting() {
			const _this = this;
			_this.$store
				.dispatch(GET, {
					url: `lead-setting`,
				})
				.then((data) => {
					if (data) {
						this.sourceList = data?.source;
					}
					console.log(data, "data");

					// this.$emit("success", true);
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
		getReminder(id, type) {
			if (!type) {
				this.reminderTabs = "upcoming";
				type = 1;
			}
			this.$store
				.dispatch(QUERY, {
					url: `reminder`,
					data: { related_id: id, related_to: "lead", status: type ? type : 1 },
				})
				.then((data) => {
					if (type == 1) {
						this.reminderTabs = "overdue";
					} else if (type == 2) {
						this.reminderTabs = "upcoming";
					} else if (type == 3) {
						this.reminderTabs = "complete";
					}
					this.reminderData = data.rows;
					this.reminderCounting = data.countings ? data.countings : {};
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		open_reminder_drawer(data) {
			this.lead_id = data.id ? data.id : 0;
			if (data && data.reminder) {
				this.reminder_id = data.reminder.id ? data.reminder.id : 0;
			}
			this.lead_company = data && data.company_name ? data.company_name : null;
			this.meeting_title = "Create";
			this.editReminderData = {};
			this.attendieList = {};
			this.open_reminder_dialog = true;
			if (this.lead_id > 0 && this.reminder_id) {
				this.editReminderForm(this.reminder_id, data.reminder);
				this.meeting_title = "Update";
			}
			/* this.meeting_title = name; */
		},
		open_reminder_list(data) {
			this.lead_company = data.company_name;
			this.reminderId = data.id;
			this.getReminder(data.id, 2);
			this.reminderListDialog = true;
			this.reminderTabs = "upcoming";
		},
		getdata() {
			this.$emit("createSuccess", true);
		},
		getFirstTwoLetters(name) {
			if (!name) return "";
			return name.slice(0, 2).toUpperCase();
		},
		copyNumber(data, type) {
			if (type == "phone") {
				data = data.split("-").pop();
			}
			navigator.clipboard.writeText(data);
			this.$store.commit(SET_MESSAGE, [
				{ model: true, message: `${type} <b>${data}</b> - successfully coppied.` },
			]);
		},
		openRemark(data) {
			this.leadBarcode = data.barcode;
			this.name = data.name;
			console.log(this.name, "leadBarcode");
			this.default_remakr = data.remark;
			this.default_date = toString(MomentJS(data.added_at));
			this.lead_id = data.id;
			/* this.getRemark(data.id); */

			this.remarkDialog = true;
		},
		closeDialog(value) {
			if (value == "reminder") {
				this.reminderDialog = false;

				this.getLeadListing();
			} else {
				this.remarkDialog = false;
			}
		},
		openAction(action, id) {
			switch (action.value) {
				case "view_opportunity":
					this.$router.push({ name: "opportunity-detail", params: { id: id } });
					break;
				case "edit_opportunity":
					this.id = id;
					this.update_opportunity = true;
					break;
				case "delete_opportunity":
					break;
			}
		},
		updatePagination() {},
		updateOpportunity(value, id, type, index, text) {
			if (type == "status") {
				this.tbody[index].status = value;
			} else if (type == "assign") {
				this.tbody[index].user_name = text;
			} else if (type == "opportunity") {
				this.tbody[index].opportunity = value;
			} else if (type == "product_type") {
				this.tbody[index].product_type = value;
			} else if (type == "source") {
				this.tbody[index].source = value;
			}
		},
		leadStatus(status) {
			let found = this.statusList.find((v) => v.value == status);
			if (found) {
				return found?.text;
			} else {
				return null;
			}
		},
		getColorClass(start_date = "") {
			var startDate = MomentJS(start_date).format("YYYY-MM-DD");
			var now = MomentJS().format("YYYY-MM-DD");
			if (startDate < now) {
				return "red-color";
			} else if (startDate >= now) {
				return "blue-color";
			}
		},
		leadStatusColor(status) {
			switch (status) {
				case "Not Contacted":
					return { border: "grey", text: "grey" };
				case "Follow UP":
					return { border: "orange", text: "orange" };
				case "Meeting":
					return { border: "#2196f3", text: "blue" };
				case "Quotation":
					return { border: "#e91e63", text: "pink" };
				case "Rejected":
					return { border: "red", text: "red" };
				case "Accepted":
					return { border: "green", text: "green" };
				case "50-50":
					return { border: "#2196f3", text: "blue" };
				case "high-chance":
					return { border: "green", text: "green" };
				case "low-chance":
					return { border: "grey", text: "grey" };
				case "none":
					return { border: "grey", text: "grey" };
				case null:
					return { border: "grey", text: "grey" };
			}
		},
		_getValue(obj, key) {
			return objectPath.get(obj, key);
		},
	},

	mounted() {
		// this.getTaskStatus();

		this.getSetting();
		/* this.topBarFilter(); */
	},

	computed: {
		taskStatusColor: function () {
			return function name(row, type) {
				if (type == "source") {
					return "blue--text";
				}
				if (type == "product_type") {
					return "green--text";
				}
				if (type == "status") {
					return "orange--text";
				}
				if (type == "opportunity") {
					return row.opportunity == "high-chance"
						? "orange--text"
						: row.opportunity == "low-chance"
						? "cyan--text"
						: row.opportunity == "50-50"
						? "green--text"
						: "blue--text";
				}
			};
		},
		taskStatusBorder: function () {
			return function name(row, type) {
				if (type == "source") {
					return "border:1px solid #2196F3";
				}
				if (type == "product_type") {
					return "border:1px solid green";
				}
				if (type == "status") {
					return "border:1px solid orange";
				}
				if (type == "opportunity") {
					return row.opportunity == "high-chance"
						? "border:1px solid orange"
						: row.opportunity == "low-chance"
						? "border:1px solid cyan"
						: row.opportunity == "50-50"
						? "border:1px solid green"
						: "border:1px solid gray";
				}
			};
		},
		...mapGetters([
			"selected",
			"tbody",
			"currentUser",
			"currentPage",
			"totalPage",

			"showingString",
			"thead",
			"total",
			"moduleSettings",
		]),
	},
};
</script>

<style scoped>
.bt-table tbody tr:nth-child(even) {
	background-color: rgb(237, 242, 251);
}
.bt-table thead tr th:last-child {
	position: sticky;
	right: 0;
	z-index: 7;
}
.bt-table tbody tr td:last-child {
	position: sticky;
	right: 0;
	z-index: 7;
}
.text_climp {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 3;
	overflow: hidden;
}
.text_climp_notes {
	width: 150px;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 1;
	overflow: hidden;
}
.text_climpNotes {
	width: 500px;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 1;
	overflow: hidden;
}
</style>
